import React from 'react'
import PropTypes from 'prop-types'

const ZeppelinIcon = ({ className, width, height, fill, title, viewBox, ariaLabel }) => {
  const label = ariaLabel || title
  return (
    <svg
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
      viewBox={viewBox}
      aria-labelledby={`title_${ label }`}
      className={className}
    >
      <title id={`title_${ label }`}>{label}</title>
      <path
        fill={fill}
        d="M117.5,0.6v1.2h0.2V1.3h0.2c0.1,0,0.2,0,0.3,0s0.1,0.1,0.1,0.3v0.2l0,0l0,0l0,0h0.2l0,0c0,0,0,0,0-0.1V1.6V1.5
c0-0.1,0-0.1-0.1-0.2c0-0.1-0.1-0.1-0.2-0.1c0.1,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.1,0.1-0.3c0-0.2-0.1-0.3-0.2-0.3c-0.1,0-0.2,0-0.3,0
h-0.5V0.6z M118.2,1c0,0.1,0,0.2-0.1,0.2c0,0-0.1,0-0.2,0h-0.2V0.7h0.2c0.1,0,0.2,0,0.3,0C118.2,0.8,118.2,0.9,118.2,1 M119.1,1.2
c0-0.3-0.1-0.6-0.3-0.8c-0.2-0.2-0.5-0.3-0.8-0.3s-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.5-0.3,0.8s0.1,0.6,0.3,0.8
c0.2,0.2,0.5,0.3,0.8,0.3s0.6-0.1,0.8-0.3C119,1.8,119.1,1.5,119.1,1.2 M117.9,0.2c0.3,0,0.5,0.1,0.7,0.3c0.2,0.2,0.3,0.4,0.3,0.7
c0,0.3-0.1,0.5-0.3,0.7s-0.4,0.3-0.7,0.3s-0.5-0.1-0.7-0.3c-0.2-0.2-0.3-0.4-0.3-0.7c0-0.3,0.1-0.5,0.3-0.7
C117.4,0.3,117.7,0.2,117.9,0.2 M24.8,0h11.3v2.3l-6.3,7.1h6.3v2.3H24.5V9.4l6.2-7.1h-5.9L24.8,0L24.8,0z M106.3,0l5.6,6.9V0h3.5
v11.6h-3.5l-5.6-7v7h-3.5V0H106.3L106.3,0z M96.1,0h4v11.6h-4V0L96.1,0z M86.5,0h2.1h1.8v4.7v4.7h2.1h2.2v2.3h-4.2h-4.1L86.5,0
L86.5,0z M38.5,0h4.6h4.7v1.2v1.2h-2.7h-2.7v1.2v1.2h2.4h2.6v1V7h-2.6h-2.4v1.2v1.1h5.7v1.2v1.1h-4.9h-4.8V0H38.5z M56.6,5.9
c-0.5,0.5-1.5,0.5-1.5,0.5h-0.7v-4h0.7c0,0,0.9,0,1.3,0.2c0.6,0.4,0.8,1,0.8,1.7C57.1,4.9,57,5.4,56.6,5.9 M57.8,0.3
C56.8,0,54.6,0,54.6,0h-1.7l0,0h-2.6v11.6h4.1V8.8c0.4,0,0.8,0,1.1,0c0.6,0,1.7-0.1,2.5-0.4c2-0.7,3-2.4,3-4.1
C60.9,2.6,60,1,57.8,0.3 M74.6,0h4.6h4.7v1.2v1.2h-2.7h-2.6v1.2v1.2H81h2.6v1V7H81h-2.4v1.2v1.1h5.7v1.2v1.1h-4.9h-4.8V0L74.6,0z
M68.7,5.9c-0.5,0.5-1.5,0.5-1.5,0.5h-0.7v-4h0.7c0,0,0.9,0,1.3,0.2c0.6,0.4,0.8,1,0.8,1.7C69.2,4.9,69.2,5.4,68.7,5.9 M70,0.3
C69,0,66.8,0,66.8,0h-1.7l0,0h-2.6v11.6h4.1V8.8c0.4,0,0.8,0,1.1,0c0.6,0,1.7-0.1,2.5-0.4c2-0.7,3-2.4,3-4.1C73.1,2.6,72.2,1,70,0.3
"
      />
      <path
        fill={fill}
        d="M17.7,0v11.6H9.4L13,7.5V4.2H4.6V0H17.7L17.7,0z M0,7.5v11.6h13v-4.2H4.6v-3.3l3.6-4.1L0,7.5L0,7.5z"
      />
      <path
        fill={fill}
        d="M26.6,17.5c0,0.1-0.1,0.3-0.1,0.4c0,0.2-0.1,0.4-0.1,0.5c0-0.2-0.1-0.3-0.1-0.5s-0.1-0.3-0.1-0.5
c-0.1-0.4-0.2-0.7-0.3-1.1c-0.1-0.3-0.1-0.5-0.2-0.8c-0.1-0.2-0.1-0.4-0.2-0.6h-1l0.7,2.1l0.6,2.1h1.1l0.7-2.9l0.1-0.6l0.2,0.7
l0.8,2.8h1.1c0.1-0.3,0.2-0.6,0.3-1s0.3-0.9,0.4-1.3l0.6-1.9h-0.8l-0.7,2.7l-0.2,0.7l-0.2-0.7c-0.1-0.4-0.2-0.7-0.3-1.1
c-0.1-0.3-0.2-0.6-0.2-1c-0.1-0.2-0.1-0.5-0.2-0.7h-1.1L26.6,17.5L26.6,17.5z M31.9,15v4.2h3.3v-0.7h-2.5v-1.2h2.2v-0.7h-2.2v-0.9
H35V15L31.9,15L31.9,15z M43.1,16.3c0-0.1-0.1-0.4-0.2-0.6c-0.1-0.2-0.2-0.3-0.2-0.4c-0.1-0.1-0.2-0.2-0.3-0.2
c-0.5-0.3-1.2-0.3-1.3-0.3c-0.2,0-0.5,0-0.6,0c-1.1,0.1-1.5,0.7-1.7,1.1c-0.1,0.2-0.2,0.5-0.2,1c0,0.7,0.2,1.4,0.9,1.8
c0.6,0.4,1.2,0.4,1.4,0.4c0.4,0,0.8-0.1,1.2-0.2c0.4-0.2,0.5-0.4,0.6-0.5c0.2-0.3,0.2-0.6,0.2-0.7H42c0,0.2-0.1,0.3-0.2,0.5
c-0.2,0.3-0.7,0.4-1,0.4c-0.4,0-0.8-0.1-1-0.4c-0.4-0.4-0.4-1-0.4-1.2c0-0.7,0.3-1.3,1-1.4c0.1,0,0.3-0.1,0.5-0.1
c0.6,0,0.9,0.3,1,0.5c0.1,0.1,0.1,0.3,0.1,0.3L43.1,16.3L43.1,16.3z M48.1,18c0-0.2-0.1-0.4-0.2-0.5s-0.2-0.2-0.6-0.3
c0,0,0.3-0.1,0.4-0.2c0.1-0.1,0.4-0.4,0.4-0.9c0-0.6-0.4-0.8-0.6-0.9C47.1,15,46.7,15,46.2,15h-2v4.2h0.9v-1.6h1.3
c0.4,0,0.7,0,0.8,0.4c0,0.1,0.1,0.3,0.1,0.3v0.3c0,0.2,0,0.3,0.1,0.6h0.9c-0.1-0.1-0.1-0.1-0.1-0.3L48.1,18L48.1,18z M46.4,15.7
c0.3,0,0.5,0,0.7,0.1c0.3,0.1,0.3,0.4,0.3,0.5s0,0.2-0.1,0.4c-0.3,0.1-0.5,0.1-0.8,0.1h-1.3v-1.2h1.2V15.7z M49.8,15v4.2h3.3v-0.7
h-2.5v-1.2h2.2v-0.7h-2.2v-0.9h2.3V15L49.8,15L49.8,15z M56.1,15L54,19.2h0.9l0.4-0.9h2.5l0.4,0.9h1c-0.3-0.7-0.7-1.4-1-2.1
c-0.3-0.7-0.7-1.4-1-2.1L56.1,15L56.1,15z M56.6,15.6l0.9,1.9h-1.9L56.6,15.6L56.6,15.6z M59.2,15v0.7h1.7v3.5h0.9v-3.5h1.7V15H59.2
L59.2,15z M64.5,15v4.2h3.3v-0.7h-2.4v-1.2h2.2v-0.7h-2.2v-0.9h2.3V15L64.5,15L64.5,15z M75.3,16.3c0-0.2,0-0.4-0.1-0.7
c0-0.1-0.1-0.2-0.3-0.4c-0.4-0.3-0.9-0.4-1.1-0.4c-0.1,0-0.2,0-0.4,0c-0.9,0-1.4,0.2-1.6,0.5c-0.1,0.1-0.3,0.3-0.3,0.8
c0,0.7,0.5,0.9,0.7,1s0.4,0.1,0.5,0.1l1.1,0.1c0.2,0,0.5,0.1,0.6,0.2c0,0,0.1,0.1,0.1,0.3c0,0.1,0,0.2-0.1,0.3
c0,0.1-0.1,0.1-0.2,0.2c-0.3,0.2-0.9,0.2-0.9,0.2c-0.6,0-0.9-0.2-1.1-0.4c-0.1-0.2-0.1-0.4-0.1-0.4l-0.7,0.1c0,0.3,0.1,0.5,0.2,0.8
c0.3,0.6,1.2,0.7,1.8,0.7c0.2,0,0.3,0,0.5,0s0.5-0.1,0.7-0.1c0.2-0.1,1-0.4,1-1.2c0-0.2,0-0.4-0.1-0.4c-0.1-0.2-0.3-0.6-0.9-0.7
c-0.1,0-0.2-0.1-0.4-0.1l-0.9-0.1c-0.2,0-0.5-0.1-0.6-0.1c-0.3-0.3-0.3-0.4-0.3-0.5c0-0.2,0.2-0.4,0.2-0.4c0.2-0.1,0.6-0.2,0.8-0.2
c0.1,0,0.8,0,1,0.4c0,0.1,0.1,0.2,0.1,0.4H75.3L75.3,16.3z M76.6,17.1c0,0.9,0.4,1.7,1.2,2c0.4,0.2,1,0.2,1.2,0.2
c0.1,0,0.3,0,0.6-0.1c0.4-0.1,0.6-0.2,0.7-0.2c0.7-0.4,1-1.1,1-1.9c0-0.5-0.2-1.1-0.5-1.5c-0.1-0.1-0.3-0.3-0.4-0.4
c-0.2-0.1-0.7-0.4-1.4-0.4c-0.8,0-1.4,0.3-1.6,0.5C77.1,15.6,76.6,16.1,76.6,17.1L76.6,17.1z M77.5,17c0-0.5,0.2-0.8,0.3-1
c0.4-0.5,1-0.5,1.2-0.5c0.7,0,1,0.4,1.2,0.6c0.2,0.3,0.3,0.7,0.3,1c0,0.6-0.3,1-0.5,1.2c-0.3,0.2-0.6,0.3-1,0.3
c-0.6,0-1-0.3-1.1-0.5C77.6,17.8,77.5,17.4,77.5,17L77.5,17z M82.6,15v4.2h3.3v-0.7h-2.4V15H82.6L82.6,15z M90.2,17.2
c0,0.4,0,0.7-0.3,1c-0.1,0.2-0.4,0.4-0.9,0.4c-0.8,0-1.1-0.5-1.1-0.8c-0.1-0.2-0.1-0.4-0.1-0.6v-2.3h-0.9v2.2c0,0.3,0,0.6,0.1,0.9
c0.1,0.3,0.2,0.5,0.3,0.6c0.4,0.5,1.1,0.6,1.2,0.6s0.3,0,0.4,0c0.2,0,0.5,0,0.5,0c0.1,0,0.3-0.1,0.6-0.2c0.2-0.1,0.4-0.2,0.6-0.4
c0.4-0.5,0.4-1.1,0.4-1.3c0-0.1,0-0.2,0-0.3v-2.2h-0.9v2.4H90.2z M92,15v0.7h1.7v3.5h0.9v-3.5h1.7V15H92L92,15z M97.4,15v4.2h0.9V15
H97.4L97.4,15z M99.6,17.1c0,0.9,0.4,1.7,1.2,2c0.4,0.2,1,0.2,1.2,0.2c0.1,0,0.3,0,0.6-0.1c0.4-0.1,0.6-0.2,0.7-0.2
c0.7-0.4,1-1.1,1-1.9c0-0.5-0.2-1.1-0.5-1.5c-0.1-0.1-0.3-0.3-0.4-0.4c-0.2-0.1-0.7-0.4-1.4-0.4c-0.8,0-1.4,0.3-1.6,0.5
C100.1,15.6,99.6,16.1,99.6,17.1L99.6,17.1z M100.5,17c0-0.5,0.2-0.8,0.3-1c0.4-0.5,1-0.5,1.2-0.5c0.7,0,1,0.4,1.2,0.6
c0.2,0.3,0.3,0.7,0.3,1c0,0.6-0.3,1-0.5,1.2c-0.3,0.2-0.6,0.3-1,0.3c-0.6,0-1-0.3-1.1-0.5C100.6,17.8,100.5,17.4,100.5,17L100.5,17z
M105.6,15v4.2h0.8v-2.9v-0.7l0.4,0.6c0.1,0.2,0.2,0.3,0.3,0.5c0.2,0.3,0.4,0.6,0.6,0.8c0.2,0.3,0.4,0.5,0.6,0.8s0.4,0.5,0.6,0.8
h1.2v-4.2h-0.7v2.8v0.7l-0.5-0.8c-0.2-0.2-0.3-0.5-0.5-0.7c-0.1-0.2-0.3-0.4-0.4-0.6c-0.1-0.2-0.2-0.3-0.3-0.5
c-0.2-0.3-0.4-0.6-0.6-0.8L105.6,15L105.6,15z M115.4,16.3c0-0.2,0-0.4-0.1-0.7c0-0.1-0.1-0.2-0.3-0.4c-0.4-0.3-0.9-0.4-1.1-0.4
c-0.1,0-0.2,0-0.4,0c-0.9,0-1.4,0.2-1.6,0.5c-0.1,0.1-0.3,0.3-0.3,0.8c0,0.7,0.5,0.9,0.7,1s0.4,0.1,0.5,0.1l1.1,0.1
c0.2,0,0.5,0.1,0.6,0.2c0,0,0.1,0.1,0.1,0.3c0,0.1,0,0.2-0.1,0.3c0,0.1-0.1,0.1-0.2,0.2c-0.3,0.2-0.9,0.2-0.9,0.2
c-0.6,0-0.9-0.2-1.1-0.4c-0.1-0.2-0.1-0.4-0.1-0.4h-0.9c0,0.3,0.1,0.5,0.2,0.8c0.3,0.6,1.2,0.7,1.8,0.7c0.2,0,0.3,0,0.5,0
s0.5-0.1,0.7-0.1c0.2-0.1,0.9-0.4,0.9-1.2c0-0.2,0-0.4-0.1-0.4c-0.1-0.2-0.3-0.6-0.9-0.7c-0.1,0-0.3-0.1-0.4-0.1l-0.9-0.1
c-0.2,0-0.5-0.1-0.6-0.1c-0.2-0.1-0.2-0.3-0.2-0.3c0-0.2,0.2-0.4,0.2-0.4c0.2-0.1,0.6-0.2,0.8-0.2c0.1,0,0.8,0,1,0.4
c0,0.1,0.1,0.2,0.1,0.4L115.4,16.3L115.4,16.3z"
      />
    </svg>
  )
}
ZeppelinIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  viewBox: PropTypes.string,
  fill: PropTypes.string,
  ariaLabel: PropTypes.string,
}

ZeppelinIcon.defaultProps = {
  width: '32',
  height: '32',
  viewBox: '0 0 119.1 19.1',
  fill: 'currentColor',
}

export default ZeppelinIcon
